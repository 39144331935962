import {NgModule} from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTabsModule} from '@angular/material/tabs';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatSortModule} from '@angular/material/sort';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {RxReactiveFormsModule} from '@rxweb/reactive-form-validators';
import {FileUploadModule} from '@iplab/ngx-file-upload';
import {MatChipsModule} from '@angular/material/chips';
import {ClipboardModule} from 'ngx-clipboard';
import {MaterialFileInputModule} from 'ngx-material-file-input';

@NgModule({
  imports: [
    FlexLayoutModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatGridListModule,
    MatTabsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatSnackBarModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    // Important: order matters. FormsModule first and then ReactiveFormsModule
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    NgxMatSelectSearchModule,
    MaterialFileInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RxReactiveFormsModule,
    MatRadioModule,
    MatCheckboxModule,
    DragDropModule,
    FileUploadModule,
    MatChipsModule,
    ClipboardModule
  ],
  exports: [
    // Important: order matters. FormsModule first and then ReactiveFormsModule
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    // End of order
    FlexLayoutModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatGridListModule,
    MatTabsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatSnackBarModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatExpansionModule,
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCheckboxModule,
    DragDropModule,
    FileUploadModule,
    MatChipsModule,
    ClipboardModule
  ],
  declarations: []
})
export class MaterialAndFlexModule {
}

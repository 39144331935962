<mat-form-field>
  <mat-label>{{label}}</mat-label>

  <mat-select [value]="multiple ? selectedValues : selectedValue"
              [disabled]="disabled"
              [multiple]="multiple"
              (selectionChange)="onSelectionChange($event)">
    <mat-option *ngFor="let option of options"
                [disabled]="option.disabled"
                [value]="option.value">
      {{option.viewValue}}
    </mat-option>
  </mat-select>

  <button *ngIf="showClearButton"
          [disabled]="disabled || (multiple ? selectedValues.length === 0 : !selectedValue)"
          matSuffix mat-icon-button aria-label="Clear"
          (click)="onClickClear($event)">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>


<!--<select *ngIf="native">-->
<!--  <option *ngFor="let value of allowedValuesValidator.allowedValues" [value]="value">-->
<!--    {{ value }}-->
<!--  </option>-->
<!--</select>-->

import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Dashboard, DashboardsService} from '../../core/services/dashboards.service';
import {UserOrganisationService} from '../../core/organisation/user-organisation.service';
import {DOCUMENT} from '@angular/common';
import {filter, map} from 'rxjs/operators';
import {RouterUtils} from '../../core/router/router-utils';

@Injectable({
  providedIn: 'root'
})
export class BrowserTabService {
  constructor(
    @Inject(DOCUMENT) document: Document,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private userOrganisationService: UserOrganisationService
  ) {
  }

  subscribeToRouteEventsForTabNames(): void {
    // Init
    this.setBrowserTab(RouterUtils.extractDashboardFromRoute(this.activatedRoute.snapshot));
    // Subscribe
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        return RouterUtils.extractDashboardFromRoute(route.snapshot);
      }))
      .subscribe(dashboard => {
        this.setBrowserTab(dashboard);
      });
  }

  private setBrowserTab(dashboard: Dashboard): void {
    this.title.setTitle(`Visual7 - ${dashboard.title}`);
    this.setFavicon(dashboard.faviconImageName);
  }

  private setFavicon(faviconImageName: string): void {
    if (faviconImageName == null) {
      faviconImageName = DashboardsService.HOME_DASHBOARD.faviconImageName;
    }
    const faviconElement: HTMLElement = document.getElementById('favicon');
    faviconElement.setAttribute('type', 'image/png');
    faviconElement.setAttribute('href', `./assets/images/favicon/${faviconImageName}.png`);
  }
}

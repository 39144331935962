import {O7Device} from './o7-device';
import {AppClass} from '../../../core/common/app/classes/app-class';

export class O7DeviceInfo {

  readonly o7Device: O7Device;

  readonly user: object;

  readonly installedApps: AppInfo[];

  readonly uninstalledApps: AppInfo[];

}

export class AppInfo {
  readonly appId: string;
  readonly uniqueAppId: string;
  readonly appName: string;
  readonly appClass: AppClass;
  readonly appClassId: string;
  readonly appUserId: string;
}

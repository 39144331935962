import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {FilterConfig} from '../classes/filter-config';
import * as _ from 'lodash';
import {catchError, map, tap} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {FilterComponent} from '../classes/filter-component';
import {Filter} from '../filter';

/**
 * @author sasoorazem
 */
@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  private static readonly FILTER_ID_PROPERTY: string = 'id';

  public static readonly BASE_URL: string = '/rest/visual7/filters';

  private filterConfigsCache: FilterConfig[] = null;

  constructor(private http: HttpClient) {
  }

  getFilterConfigs(): Observable<FilterConfig[]> {
    if (this.filterConfigsCache != null) {
      return of(this.getCachedFilterConfigs());
    }
    return this.http.get<FilterConfig[]>(FiltersService.BASE_URL).pipe(
      map(filterConfigs => {
        let convertedConfig: FilterConfig[] = [];
        _.each(filterConfigs, (config) => {
          convertedConfig.push(plainToClass(FilterConfig, config));
        });
        return convertedConfig;
      }),
      tap((filterConfigs) => {
        this.filterConfigsCache = filterConfigs;
      }),
      catchError(err => {
        console.error('Error occurred while fetching filters configurations', err);
        return throwError(err);
      })
    );
  }

  getCachedFilterConfigs(): FilterConfig[] {
    return _.cloneDeep(this.filterConfigsCache);
  }

  getFilterConfigForFilterId(filterId: string): FilterConfig {
    return this.getFilterConfigForProperty(FiltersService.FILTER_ID_PROPERTY, filterId);
  }

  getFilterConfigForProperty(property: string, value: string): FilterConfig {
    let filterConfig: FilterConfig = null;
    _.each(this.filterConfigsCache, (sourceFilterConfig: FilterConfig) => {
      if (_.get(sourceFilterConfig, property) == value) {
        filterConfig = sourceFilterConfig;
        return;
      }
    });
    return filterConfig;
  }

  isBasicFilter(filterConfig: FilterConfig): boolean {
    return _.isEqual(filterConfig.type.filterComponent.id, FilterComponent.BASIC.id);
  }

  isWhiteAndBlackListFilter(filterConfig: FilterConfig): boolean {
    return _.isEqual(filterConfig.type.filterComponent.id, FilterComponent.WHITE_OR_BLACK_LIST.id);
  }

  isRangeFilter(filterConfig: FilterConfig): boolean {
    return _.isEqual(filterConfig.type.filterComponent.id, FilterComponent.RANGE.id);
  }
}

import {Type} from 'class-transformer';
import {FilterType} from './filter-type';

/**
 * Configuration for all filters received from BE
 *
 * @author sasoorazem
 */
export class FilterConfig {
  id?: string;
  niceName?: string;
  options?: string[] = [];
  @Type(() => FilterType)
  type?: FilterType;

  constructor(id: string, niceName: string, type: FilterType, options?: string[]) {
    this.id = id;
    this.niceName = niceName;
    this.options = options;
    this.type = type;
  }

  public static hasOptions(filterConfig: FilterConfig): boolean {
    return filterConfig.options != null && filterConfig.options.length > 0;
  }
}

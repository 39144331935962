import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlackHttpService } from './services/slack-http.service';
import {StoreModule} from '@ngrx/store';
import {slackMemberFeatureKey} from './ngrx/slack-member/slack-member.state';
import {slackMemberReducer} from './ngrx/slack-member/slack-member.reducer';
import {EffectsModule} from '@ngrx/effects';
import {SlackMemberEffects} from './ngrx/slack-member/slack-member.effects';
import {SlackEffects} from './ngrx/slack/slack.effects';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(slackMemberFeatureKey, slackMemberReducer),
    EffectsModule.forFeature([
      SlackMemberEffects,
      SlackEffects
    ])
  ],
  exports: [
  ],
  providers: [
    SlackHttpService
  ]
})
export class SlackModule {}

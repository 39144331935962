import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {AppClass} from '../classes/app-class';
import * as _ from 'lodash';
import {Platform} from '../classes/platform';
import {App} from '../classes/app';
import {Builder} from 'builder-pattern';
import Utils from '../../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private static readonly BASE_URL: string = '/rest/admin/apps';

  constructor(private http: HttpClient) {
  }

  private cachedPlatforms: Platform[] = null;
  private cachedApps: App[] = null;

  getAllApps(): Observable<App[]> {
    if (this.cachedApps != null) {
      return of(this.cachedApps);
    }
    return this.http.get<App[]>(AppService.BASE_URL)
      .pipe(
        map(appProxy => {
          const apps: App[] = _.get(appProxy, 'appProxy');
          return plainToClass(App, apps, {excludeExtraneousValues: true});
        }),
        map(apps => {
          const appsWithIpad: App[] = [];
          _.each(apps, (app) => {
            appsWithIpad.push(app);
            if (app.universalApp) {
              appsWithIpad.push(Builder(App, app)
                .platformTitle(Platform.IPAD)
                .platform(Builder(Platform)
                  .title(Platform.IPAD)
                  .build())
                .build());
            }
          });
          return appsWithIpad;
        }),
        tap((apps) => {
          this.cachedApps = Utils.sortListAlphabetically(apps, 'title');
        })
      );
  }

  getPlatforms(): Observable<Platform[]> {
    if (this.cachedPlatforms != null) {
      return of(this.cachedPlatforms);
    }
    return this.http.get<Platform[]>(AppService.BASE_URL + '/all-platforms')
      .pipe(map(platforms => {
          return plainToClass(Platform, platforms);
        }), tap((platforms) => {
          this.cachedPlatforms = platforms;
        })
      );
  }

  getCachedAppClasses(discontinuedAppClasses?: boolean): AppClass[] {
    if (discontinuedAppClasses == null) {
      return _.chain(this.getCachedApps())
        .uniqBy('appClassId')
        .map('appClass')
        .value();
    }
    return _.chain(this.getCachedApps())
      .groupBy('appClassId')
      .filter(appClassesWithApps =>
        this.filterApps(discontinuedAppClasses, appClassesWithApps))
      .flatten()
      .uniqBy('appClassId')
      .map('appClass')
      .value();
  }

  private filterApps(discontinuedAppClasses: boolean, appClassesWithApps): boolean {
    if (discontinuedAppClasses) {
      return _.every(_.values(appClassesWithApps), app => app.discontinued == discontinuedAppClasses);
    } else {
      return _.some(_.values(appClassesWithApps), app => app.discontinued == discontinuedAppClasses);
    }
  }

  getCachedApps(): App[] {
    if (this.cachedApps == null) {
      throw new Error('Apps not fetched yet. Use app resolver!');
    }
    return this.cachedApps;
  }

  getCachedPlatforms(): Platform[] {
    if (this.cachedPlatforms == null) {
      throw new Error('Platforms not fetched yet. Use platform resolver!');
    }
    return this.cachedPlatforms;
  }

  getAppClassById(appClassId: string): AppClass {
    return _.find(this.getCachedAppClasses(), {classId: appClassId});
  }

  getApps(appClass: AppClass): App[] {
    return _.filter(this.getCachedApps(), {appClassId: appClass.classId});
  }

  getApp(appClass: AppClass, platform: string, store?: string): App {
    return _.chain(this.getApps(appClass))
      .filter({platformTitle: platform})
      .find((app) => !app.releasedOnAppStores || app.releasedOnAppStores.includes(store))
      .value();
  }

  getSupportedPlatforms(appClass: AppClass): string[] {
    return _.chain(this.getApps(appClass)).map('platformTitle').flatten().uniq().value();
  }

  getAppClassesWithAppUserState(): AppClass[] {
    return _.filter(this.getCachedAppClasses(), appClass => appClass.appUserStateClassSimpleName != null);
  }

}

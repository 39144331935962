import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {take, takeUntil} from 'rxjs/operators';
import {ReplaySubject, Subject} from 'rxjs';
import {MatSelect} from '@angular/material/select';
import * as _ from 'lodash';
import {MatFormFieldAppearance} from '@angular/material/form-field';

/**
 * Angular Material is missing select search component.
 * This one temporarily until they create it.
 *
 * Usage: If input is array of objects then you should define displayField.
 *
 * @author sasoorazem
 */
@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectSearchComponent),
      multi: true
    }
  ]
})
export class SelectSearchComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor, OnChanges {

  @Output() selectedObjectChange: EventEmitter<object | object[]> = new EventEmitter();
  @Input() placeHolder: string = 'Search';
  @Input() appearanceMode: MatFormFieldAppearance = 'standard';
  @Input() multiple: boolean;
  @Input() disabled: boolean = false;
  @Input() enableClear: boolean = true;
  @Input() triggerField: string;
  @Input() hideStandardModeUnderline: boolean = false;

  /**
   * If fields is not set, this means the objects are primitive values (string, int, ...)
   */
  @Input() private displayField: string;

  /**
   * On which fields should the search be done (if this is null, the display field is used)
   */
  @Input() private searchFields: string[];

  @Input() private objects: object[] = [];

  // In case you want to put some objects filtered from another dropdown on top you can add them here and they will be shown on top and
  // separated from 'objects' below by a thin line
  @Input() topObjects: object[] = null;

  @Input() private allObjects: object[] = [];

  @Input() private disabledObjects: object[] = [];

  @Input() private selectedObject: object | object[];

  /**
   * If allow empty is enabled, the empty option will be added
   */
  @Input() allowEmpty: boolean;

  @Input() floatLabel: string = 'auto';

  @Input() adjustPanelWidthToText: boolean = false;

  selectForm: FormControl = new FormControl();

  /**
   * Control for the MatSelect filter keyword
   */
  objectFilterCtrl: FormControl = new FormControl();

  /**
   *  List of object filtered by search keyword
   */
  filteredObjects: ReplaySubject<object[]> = new ReplaySubject<object[]>(1);
  filteredTopObjects: ReplaySubject<object[]> = new ReplaySubject<object[]>(1);

  @ViewChildren('multiSelect') multiSelect: MatSelect;

  /**
   *  Subject that emits when the component has been destroyed.
   */
  protected _onDestroy: Subject<void> = new Subject();

  constructor() {
  }

  ngOnInit(): void {
    this.filteredObjects.next(this.objects?.slice());
    this.filteredTopObjects.next(this.topObjects?.slice());
    // listen for search field value changes
    this.objectFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterObjects();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (_.has(changes, 'objects')) {
      this.ngOnInit();
      // If objects change we need to revaluate if selected object is still valid
      if ((!_.isArray(this.selectedObject) && !this.findObject(this.selectedObject))) {
        this.selectForm.setValue(null);
        this.selectedObject = null;
      } else if (_.isArray(this.selectedObject)) {
        _.forEach(this.selectedObject, selectedObject => {
          if (!this.findObject(selectedObject)) {
            this.selectForm.setValue(_.filter(this.selectForm.value, element => element !== selectedObject));
            this.selectedObject = _.filter(this.selectedObject, element => element !== selectedObject);
          }
        });
      }
    }
    if (_.has(changes, 'topObjects')) {
      this.filteredTopObjects.next(this.topObjects?.slice());
    }
    if (_.has(changes, 'selectedObject')) {
      this.onExternalSelectedObjectChange(changes.selectedObject.previousValue);
    }
    if (_.has(changes, 'disabled')) {
      if (changes.disabled.currentValue) {
        this.selectForm.disable();
      } else {
        this.selectForm.enable();
      }
    }
  }

  onExternalSelectedObjectChange(previousObject: object | object[]): void {
    if (_.isEqual(this.selectedObject, previousObject)) {
      return;
    }
    if (this.multiple) {
      let valuesToPatch: object[] = [];
      _.forEach(this.selectedObject as object[], (value) => {
        let sameObject: object = this.findObject(value);
        valuesToPatch.push(sameObject);
      });
      this.selectForm.setValue(valuesToPatch);
    } else {
      this.selectForm.setValue(this.selectedObject);
    }
    this.onChange(this);
  }

  onSelectedObjectChange(): void {
    let value: object | object[] | null = this.selectForm.value;
    this.selectedObject = value;
    this.selectedObjectChange.emit(value);
    this.onChange(value);
  }

  ngAfterViewInit(): void {
    this.setInitialValue();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue(): void {
    this.filteredObjects
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = this.customCompareWith;
      });
    this.filteredTopObjects
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = this.customCompareWith;
      });
  }

  protected filterObjects(): void {
    if (!this.objects) {
      return;
    }
    // get the search keyword
    let search: string = this.objectFilterCtrl.value;
    if (!search) {
      this.filteredObjects.next(this.objects.slice());
      this.filteredTopObjects.next(this.topObjects?.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter objects
    this.filteredObjects.next(
      this.objects.filter(object => {
        if (this.searchFields) {
          return this.searchFields.some(s => _.get(object, [s]).toLowerCase().indexOf(search) > -1);
        } else {
          return this.getDisplayField(object).toLowerCase().indexOf(search) > -1;
        }
      })
    );
    this.filteredTopObjects.next(
      this.topObjects?.filter(object => {
        if (this.searchFields) {
          return this.searchFields.some(s => _.get(object, [s]).toLowerCase().indexOf(search) > -1);
        } else {
          return this.getDisplayField(object).toLowerCase().indexOf(search) > -1;
        }
      })
    );
  }

  getDisplayField(object: any): string {
    return this.displayField ? _.get(object, [this.displayField]) : object;
  }

  private findObject(object: any): object {
    if (this.displayField) {
      return _.find(this.objects, object);
    }
    // If display field is not set this is a simple string object
    return object;
  }

  customCompareWith = (a: object, b: object) => {
    return a && b && this.getDisplayField(a) === this.getDisplayField(b);
  };

  /**
   * Abstract Control Interface Implementations
   */
  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(values: any): void {
    if (this.multiple) {
      let newValues: object[] = [];
      _.forEach(values, (value) => {
        newValues.push(this.findObject(value));
      });
      this.selectForm.setValue(newValues);
    } else {
      this.selectForm.setValue(values);
    }
  }

  clearSearch(event: Event): void {
    event.stopPropagation();
    this.selectForm.setValue([]);
    this.onSelectedObjectChange();
  }

  isObjectDisabled(object: object): boolean {
    return this.disabledObjects.includes(object);
  }

  getOtherOptions(objects: object[] | null, topObjects: object[] | null): object[] {
    return _.filter(objects, object => {
      return !topObjects || !topObjects.some(s => this.getDisplayField(object) == this.getDisplayField(s));
    });
  }
}


import {FilterComponent} from './filter-component';

/**
 * Very important: must be in sync with BE
 */
export class FilterType {
  // Basic
  static readonly STRING_COLLECTION: FilterType = new FilterType('stringCollectionBasic', FilterComponent.BASIC);
  static readonly STRING: FilterType = new FilterType('stringBasic', FilterComponent.BASIC);
  // For filters like forO7Devices, forPaidUser..
  static readonly ONLY_OR_EXCLUDE: FilterType = new FilterType('onlyOrExcludeBasic', FilterComponent.BASIC);
  // Currently only disabled uses this
  static readonly BOOLEAN: FilterType = new FilterType('booleanBasic', FilterComponent.BASIC);

  // Range
  static readonly VERSION_RANGE: FilterType = new FilterType('versionRange', FilterComponent.RANGE);
  static readonly DATE_RANGE: FilterType = new FilterType('dateRange', FilterComponent.RANGE);
  static readonly DOUBLE_RANGE: FilterType = new FilterType('doubleRange', FilterComponent.RANGE);
  static readonly INTEGER_RANGE: FilterType = new FilterType('integerRange', FilterComponent.RANGE);
  static readonly SPLIT_INDEX_RANGE: FilterType = new FilterType('splitIndexRange', FilterComponent.RANGE);
  static readonly SALTED_SPLIT_INDEX_RANGE: FilterType = new FilterType('saltedSplitIndexRange', FilterComponent.RANGE);

  // White & Black list
  static readonly WHITE_OR_BLACK_LIST: FilterType = new FilterType('whiteOrBlackList', FilterComponent.WHITE_OR_BLACK_LIST);

  public id: string;
  public filterComponent: FilterComponent;

  private constructor(id: string, filterComponent: FilterComponent) {
    this.id = id;
    this.filterComponent = filterComponent;
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {setActiveAppClasses} from './app-class.actions';
import {AppClass} from '../classes/app-class';
import {AppService} from '../services/app.service';
import {BE_VERSION_VERIFIED} from '../../../../ngrx/common/module-initialization.actions';

@Injectable()
export class AppClassEffects {

  constructor(private actions$: Actions,
              private appService: AppService
  ) {}

  setActiveAppClasses$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(BE_VERSION_VERIFIED),
    switchMap(() => this.appService.getAllApps()),
    map(() => {
      let activeAppClasses: AppClass[] = this.appService.getCachedAppClasses(false);
      return setActiveAppClasses({activeAppClasses: activeAppClasses});
    })
  ));
}

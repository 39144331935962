import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {tap} from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private snackBar: MatSnackBar) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        tap(() => {}, error => {
          // Display snackbar warning if error isn't unauthorised
          if (!(error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403))) {
            let errors: any[] = error?.error?.error?.errors;
            let errorMessage: string = 'Sorry, there was an error';
            if (errors) {
              errorMessage += ': ' + errors[0]?.detail;
            } else if (typeof(error?.error) === 'string') {
              errorMessage += ': ' + error?.error;
            } else {
              errorMessage += ': ' + error?.error?.error;
            }
            this.snackBar.open(
              errorMessage,
              'Dismiss',
              {verticalPosition: 'top'}
            );
          } else {
            // Request with insufficient permissions shouldn't be made. Check that roles match on BE or stop the request on FE.
            console.error('Request with insufficient permissions made.');
          }
        })
      );
  }
}

import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';
import {AuthenticationPropertyKey} from './authentication-property-key';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private static PROPERTY_DELIMITER: string = ':';

  constructor(
    private keycloakService: KeycloakService,
    private http: HttpClient
  ) {
  }

  getProperties(propertyKey: AuthenticationPropertyKey): string[] {
    let clientRoles: string[] = _.get(this.keycloakService.getKeycloakInstance().tokenParsed, ['resource_access', propertyKey.resourceId, 'roles'], []);
    let properties: string[] = [];
    _.each(clientRoles, (role) => {
      properties.push(AuthenticationService.extractProperties(role, propertyKey));
    });
    return properties;
  }

  private static extractProperties(role: String, propertyKey: AuthenticationPropertyKey): string {
    let roleSplit: string[] = role.split(AuthenticationService.PROPERTY_DELIMITER, 2);
    if (roleSplit.length > 1 && roleSplit[0] === propertyKey.propertyName) {
      return roleSplit[1];
    }
  }

  hasRole(role: string): boolean {
    return this.hasAnyRole([role]);
  }

  hasAnyRole(requiredRoles: string[]): boolean {
    if (_.isEmpty(requiredRoles)) {
      return true;
    }
    const userKeycloakRoles: string[] = this.keycloakService.getUserRoles();
    if (_.isEmpty(userKeycloakRoles)) {
      return false;
    }

    return userKeycloakRoles.some( (userRole: string) => {
      return requiredRoles.indexOf(userRole) !== -1;
    });
  }

  hasAccessToRealmManagement(): boolean {
    return _.get(this.keycloakService.getKeycloakInstance().tokenParsed,
      'resource_access.realm-management.roles', []).includes('view-realm');
  }

  getEmailOrUsername(): string {
    let email: string = _.get(this.keycloakService.getKeycloakInstance().tokenParsed, 'email');
    return _.defaultTo(email, this.keycloakService.getUsername());
  }

  requestDashboardAccess(dashboardName: string): Observable<any> {
    return this.http.get('/rest/visual7/admin/accounts/permissions/demand', {
      params: {
        client: dashboardName
      }
    });
  }

  logout(): void {
    const email: Promise<void> = this.keycloakService.logout();
  }

}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {AuthenticationService} from '../authentication/authentication.service';
import * as _ from 'lodash';
import {from, Observable} from 'rxjs';

/**
 * Allows or denies access to paths requiring specific roles defined in app's router.
 */
@Injectable()
export class O7AuthGuard extends KeycloakAuthGuard implements CanActivateChild {

  constructor(
    protected router: Router,
    protected keycloakService: KeycloakService,
    private authenticationService: AuthenticationService
  ) {
    super(router, keycloakService);
  }

  isAccessAllowed(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      if (!this.authenticated) {
        this.keycloakService.login();
        return;
      }
      const requiredRoles: string[] = _.defaultTo(_.get(route, 'data.allowedRoles'), []);
      if (requiredRoles.length == 0 || this.authenticationService.hasAnyRole(requiredRoles)) {
        resolve(true);
      } else {
        const dashboardName: string = _.get(route, 'data.dashboard.title', '');
        this.router.navigate(['unauthorized', dashboardName]);
      }
    });
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from(this.isAccessAllowed(childRoute));
  }

}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {AppService} from '../services/app.service';
import {App} from '../classes/app';
import {Observable} from 'rxjs';

@Injectable()
export class AppsResolve implements Resolve<App[]> {

  constructor(private appService: AppService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<App[]> {
    return this.appService.getAllApps();
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

export const UID_QUERY_PARAM: string = 'uid';

@Injectable()
export class QueryParamService {

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  updateParam(param: string, value: any): void {
    this.router.navigate([], {
      replaceUrl: true,
      queryParams: {
        [param]: value ? value : '',
      },
      queryParamsHandling: 'merge'
    });
  }

  updateParams(params: Params): void {
    this.router.navigate([], {
      replaceUrl: true,
      queryParams: params,
      queryParamsHandling: 'merge'
    });
  }

  removeParam(paramToRemove: string): void {
    let params: object = {};
    params[paramToRemove] = null;
    this.router.navigate([], {
      replaceUrl: true,
      queryParams: params,
      queryParamsHandling: 'merge'
    });
  }

  hasQueryParam(param: string): boolean {
    return !!this.route.snapshot.queryParams[param];
  }
}

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import {AppModule} from './app/app.module';
import {environment} from '@environment';

if (environment.angularProductionMode) {
  enableProdMode();
  Sentry.init({
    dsn: 'https://b9e810f4374942309db5c545d5b18f95@o446669.ingest.sentry.io/5936608',
    release: process.env.O7RELEASE,
    autoSessionTracking: true,
    sampleRate: 1.0,
  });
  console.log('Sentry initialised');
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectOption} from '../../models/select-option.model';
import {MatSelectChange} from '@angular/material/select/select';


@Component({
  selector: 'app-o7-select',
  templateUrl: './o7-select.component.html',
  styleUrls: ['./o7-select.component.scss']
})

export class O7SelectComponent<T> implements OnInit {
  @Input() label: string | undefined;
  @Input() selectedValue: T | undefined;
  @Input() selectedValues: T[] = [];
  @Input() disabled: boolean = false;
  @Input() multiple: boolean = false;
  @Input() options: SelectOption<T>[] = [];
  @Input() showClearButton: boolean = true;

  @Output() selectionChange: EventEmitter<T | T[] | undefined> = new EventEmitter<T | T[] | undefined>();

  clearButtonDisabled: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  onSelectionChange(change: MatSelectChange): void {
    this.emitSelectionChane(change.value);
  }

  onClickClear(event: MouseEvent): void {
    if (this.multiple) {
      this.selectedValues = [];
      this.emitSelectionChane(this.selectedValues);
    } else {
      this.selectedValue = undefined;
      this.emitSelectionChane(this.selectedValue);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  private emitSelectionChane(value: T | T[]): void {
    this.selectionChange.emit(value);
  }
}

import {Builder} from 'builder-pattern';

export class AuthenticationPropertyKey {
  public static ORGANISATION: AuthenticationPropertyKey = Builder(AuthenticationPropertyKey)
    .resourceId('VISUAL7')
    .propertyName('ORGANISATION')
    .build();

  readonly resourceId: string;
  readonly propertyName: string;
}

import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {FiltersService} from "../services/filters.service";

@Injectable()
export class FilterResolve implements Resolve<any> {

  constructor(private filterService: FiltersService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.filterService.getFilterConfigs();
  }
}

import {AppShortUrlUtil} from '../util/app-short-url-util';

export class AppClass {

  readonly title: string;
  readonly classId: string;
  readonly homepageUrl: string;
  readonly shortUrlId: string;
  readonly appUserStateClassSimpleName: string;
  readonly configuration: Configuration;

  getShortUrl(): string {
    return `${AppShortUrlUtil.getShortDomain(this.configuration.hyperDotStudios)}/${this.shortUrlId}`;
  }

  isStarliteApp(): boolean {
    return this.configuration.engineType == 'STARLITE';
  }

}

class Configuration {
  readonly signatureMagic: string;
  readonly hyperDotStudios: boolean;
  readonly appUserStateClass: string;
  readonly engineType: string;
}

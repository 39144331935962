import {SelectOption} from '../../shared/models/select-option.model';

export class DevVersionData {

  readonly beDevVersions: string[];

  readonly beDevRegions: SelectOption<string>[];

  readonly feDevVersions: string[];
}

import {Organisation} from '../../entities/organisation';

export interface Organisations {
  [id: string]: Organisation;
}

export interface CommonState {
  readonly organisations: Organisations;
}

export const initialState: CommonState = {
  organisations: {}
};

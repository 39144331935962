import {createAction, props} from '@ngrx/store';

export const setUserOrganisation: any = createAction(
  '[Set] Set User Organisation',
  props<{ userOrganisation: string }>()
);

export const setUserRoles: any = createAction(
  '[Set] Set User Roles',
  props<{ userRoles: string[] }>()
);

export const setUserEmail: any = createAction(
  '[Set] Set User Email',
  props<{ userEmail: string }>()
);

import {KeycloakService} from 'keycloak-angular';
import {WINDOW} from '../providers/window-provider';
import {Inject, Injectable} from '@angular/core';
import {KEYCLOAK_INIT} from '../../ngrx/common/module-initialization.actions';
import {Store} from '@ngrx/store';

export function initializeKeycloak(o7KeycloakInitializer: O7KeycloakInitializer): () => Promise<boolean> {
  return o7KeycloakInitializer.initializeKeycloak();
}

const PROXY_HOSTNAMES: Array<string> = ['apps2.outfit7.com', 'apps2-hk.outfit7.com'];
const DEFAULT_KEYCLOAK_URL: string = 'https://accounts-be.outfit7.net/auth/';
const PROXY_KEYCLOAK_URL: string = 'https://accounts-be2.outfit7.net/auth/';

@Injectable({
  providedIn: 'root'
})
export class O7KeycloakInitializer {

  constructor(private keycloakService: KeycloakService,
              private store: Store,
              @Inject(WINDOW) private window: Window) {
  }

  initializeKeycloak(): () => Promise<boolean> {
    return (): Promise<boolean> => {
      return this.keycloakService.init({
        config: {
          url: this.resolveKeycloakServerUrl(),
          realm: 'backend',
          clientId: 'VISUAL7'
        },
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: false
        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: ['/assets', '/clients/public'],
        loadUserProfileAtStartUp: true
      }).then((value: boolean) => {
        this.store.dispatch({type: KEYCLOAK_INIT});
        return value;
      });
    };
  }

  resolveKeycloakServerUrl(): string {
    const isOnReverseProxy: boolean = window.location != undefined && PROXY_HOSTNAMES.indexOf(window.location.hostname) != -1;
    return isOnReverseProxy ? PROXY_KEYCLOAK_URL : DEFAULT_KEYCLOAK_URL;
  }
}

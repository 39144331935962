import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {AppService} from '../services/app.service';
import {Platform} from '../classes/platform';

@Injectable()
export class PlatformResolve implements Resolve<Platform[]> {

  constructor(private appService: AppService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.appService.getPlatforms();
  }
}

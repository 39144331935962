import {ActionReducer, createReducer, on} from '@ngrx/store';
import {setUserEmail, setUserOrganisation, setUserRoles} from './dashboard-user.actions';

export interface DashboardUserContainer {
  readonly userOrganisation: string;
  readonly userRoles: string[];
  readonly userEmail: string;
}

export const initialState: DashboardUserContainer = {
  userOrganisation: null,
  userRoles: [],
  userEmail: null
};

export const dashboardUserReducer: ActionReducer<DashboardUserContainer> = createReducer(
  initialState,
  on(setUserOrganisation, (state, {userOrganisation}) => {
    return {...state, userOrganisation: userOrganisation};
  }),
  on(setUserRoles, (state, {userRoles: userRoles}) => {
    return {...state, userRoles: userRoles};
  }),
  on(setUserEmail, (state, {userEmail}) => {
    return {...state, userEmail: userEmail};
  })
);

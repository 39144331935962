import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {SlackHttpService} from '../../services/slack-http.service';
import * as SlackMemberActions from './slack-member.actions';
import { SlackMember } from '../../models/slack-member.model';
import * as ModuleInitializationActions from '../../../../ngrx/common/module-initialization.actions';


@Injectable()
export class SlackMemberEffects {

  constructor(private actions$: Actions,
              private store: Store,
              private snackBar: MatSnackBar,
              private slackService: SlackHttpService) {
  }

  beVersionVerified$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ModuleInitializationActions.BE_VERSION_VERIFIED),
    map(() => SlackMemberActions.loadAll())
  ));

  load$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SlackMemberActions.loadAll),
    switchMap(() => this.slackService.getSlackMembers()),
    filter((members: SlackMember[] | undefined) => !!members),
    map((members: SlackMember[]) => SlackMemberActions.loadAllSuccess({members}))
  ));

}

import {AppClass} from '../classes/app-class';

export interface AppClassState {
  activeAppClasses: AppClass[];
}

export const initialState: AppClassState = {
  activeAppClasses: [],
};

export const appClassFeatureKey: string = 'appClasses';

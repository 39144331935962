import * as _ from 'lodash';
import {SelectOption} from '../../shared/models/select-option.model';
import {Builder} from 'builder-pattern';
import moment from 'moment';

export default class Utils {

  static generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r: number = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static generateShortUUID(): string {
    return 'xxxxxxxx4xxxy'.replace(/[xy]/g, function (c: string) {
      const r: number = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static sortListAlphabetically(list: any[], sortByField: string): any[] {
    return _.sortBy(list, [sortByField]);
  }

  /**
   * In some cases initialisation creates undefined and angular forms create null values. In those cases DeepDiff
   * threats the values differently and displays a diff. In this case we set all to null;
   */
  static normalizeToNull: any = function(path: string[], key: string, lhs: object, rhs: object): object[] {
    if (lhs === undefined) { lhs = null; }
    if (rhs === undefined) { rhs = null; }
    return [lhs, rhs];
  };

  static getNameSurnameFromEmail(email: string): string {
    let name: string = email.substring(0, email.lastIndexOf('@'));
    return name.split('.').map(s => _.capitalize(s)).join(' ');
  }

  static convertToInt(value: any): number {
    if (this.isNullEmptyOrUndefined(value)) {
      return null;
    }
    return parseInt(value.toString(), 10);
  }

  static convertToFloat(value: any): number {
    if (this.isNullEmptyOrUndefined(value)) {
      return null;
    }
    return parseFloat(value.toString());
  }

  static convertToBoolean(value: any): boolean {
    if (this.isNullEmptyOrUndefined(value)) {
      return null;
    }
    if (_.isBoolean(value)) {
      return value;
    }
    throw Error(`Unable to convert to boolean. Unsupported value '${value}'`);
  }

  static splitAndTrim(value: string, separator: string = ','): string[] {
    return value === '' ?
      [] :
      _.map(_.split(value, separator), (v: string) => v.trim());
  }

  static split(value: string, separator: string = ','): string[] {
    return value === '' ?
      [] :
      _.map(_.split(value.toLowerCase(), separator), (v: string) => v.trim());
  }

  static generateSelectOptions<T>(values: T[], viewValues: string[], disabledValues?: boolean[]): SelectOption<T>[] {
    if (values.length !== viewValues.length || (!!disabledValues && values.length !== disabledValues.length)) {
      console.error('Select option values length does not match view values length');
      return [];
    }

    let options: SelectOption<T>[] = _.map(values, (value: T, index: number) => ({
      value: values[index],
      viewValue: viewValues[index],
      disabled: !!disabledValues ? disabledValues[index] : false
    }));

    return options;
  }

  static replaceDiacritics(content: string): string {
    const diacriticReplacementTuples: string[][] = [
      ['š', 's'],
      ['č', 'c'],
      ['ć', 'c'],
      ['ž', 'z'],
      ['đ', 'dz']
    ];

    return _.reduce(
      diacriticReplacementTuples,
      (accum: string, replacementTuple: string[]) => _.replace(accum, new RegExp(replacementTuple[0], 'g'), replacementTuple[1]),
      content
    );
  }

  private static isNullEmptyOrUndefined(value: any): boolean {
    return value === null || value === undefined || value === '';
  }

  static deadlineDayCount(deadline: number): number {
    const diff: number = deadline - Date.now();
    const neg: boolean = diff < 0;

    return Math.ceil((neg ? -1 : 1) * moment.duration((neg ? -1 : 1) * diff, 'milliseconds').asDays());
  }
}

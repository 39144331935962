import {ActionReducer} from '@ngrx/store/src/models';
import {createReducer, on} from '@ngrx/store';
import {initialState, slackMemberAdapter, SlackMemberState} from './slack-member.state';
import * as SlackMemberActions from './slack-member.actions';

export const slackMemberReducer: ActionReducer<SlackMemberState> = createReducer(
  initialState,
  on(SlackMemberActions.loadAll, (state: SlackMemberState) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(SlackMemberActions.loadAllSuccess, (state: SlackMemberState, {members}) => {
    return {
      ...slackMemberAdapter.addMany(members, state),
      loading: false,
      loaded: true
    };
  })
);

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {concatMap, delay, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {DevVersionService} from '../../core/dev-versions/dev-version.service';
import {setBeDevVersion, setDevVersionData} from './dev-version.actions';
import {BE_VERSION_VERIFIED, KEYCLOAK_INIT} from '../common/module-initialization.actions';
import {DevVersionData} from '../../core/dev-versions/dev-version-data';
import {MatSnackBar} from '@angular/material/snack-bar';
import {getBeDevVersion, getDevVersionData} from './dev-version.selectors';
import {noOpAction} from '../common/util.actions';

@Injectable({
  providedIn: 'root'
})
export class DevVersionEffects {

  constructor(private actions$: Actions,
              private store: Store,
              private matSnackbar: MatSnackBar,
              private devVersionService: DevVersionService) {
  }

  getDevVersionData$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(KEYCLOAK_INIT),
    switchMap(() => this.devVersionService.getDevVersionData()),
    map((devVersionData: DevVersionData) => {
      return setDevVersionData({devVersionData: devVersionData});
    })
  ));

  verifyBeDevVersion$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(setBeDevVersion, setDevVersionData),
    concatMap((action: any) => of(action).pipe(
      withLatestFrom(this.store.select(getBeDevVersion), this.store.select(getDevVersionData))
    )),
    filter(([, , devVersionData]: [any, string, DevVersionData | null]) => !!devVersionData),
    map(([, beDevVersion, devVersionData]: [any, string, DevVersionData]) => devVersionData.beDevVersions.includes(beDevVersion)),
    tap((verified: boolean)  => {
      if (verified) {
        return;
      }

      this.matSnackbar
        .open(
          'Selected BE dev version is not deployed on this environment. Switching to prod!',
          null,
          {
          horizontalPosition: 'center',
          verticalPosition: 'top'
        })
        .afterOpened()
        .pipe(delay(1000))
        .subscribe(() => {
          this.devVersionService.navigateToBeVersion(DevVersionService.PROD_VERSION);
        });
    }),
    map((verified: boolean) => {
      return verified ?
        {type: BE_VERSION_VERIFIED} :
        noOpAction();
    })
  ));
}

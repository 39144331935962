import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {O7AuthGuard} from './core/guards/o7-auth.guard';
import {DashboardsService} from './core/services/dashboards.service';
import {BaseAppComponent} from './pages/base-app/base-app.component';
import {AppsResolve} from './core/common/app/resolvers/apps-resolve.service';
import {O7DevicesResolve} from './shared/o7devices/resolvers/o7-devices-resolve';
import {CountryResolve} from './core/common/country/resolvers/country-resolve';
import {DevVersionGuard} from './core/guards/dev-version.guard';
import {EnvGuard} from './core/guards/env.guard';

const routes: Routes = [
    {
      path: '',
      canActivate: [O7AuthGuard, DevVersionGuard, EnvGuard],
      canActivateChild: [O7AuthGuard],
      runGuardsAndResolvers: 'always',
      children: [
        {
          path: '',
          component: BaseAppComponent,
          children: [
            {
              path: '',
              loadChildren: () => import('./modules/homepage/homepage.module').then(m => m.HomepageModule),
              data: {
                dashboard: DashboardsService.HOME_DASHBOARD,
                allowedRoles: DashboardsService.HOME_DASHBOARD.allowedRoles
              },
            },
            {
              path: 'book-of-records',
              loadChildren: () => import('./modules/book-of-records/book-of-records.module').then(m => m.BookOfRecordsModule),
              data: {
                dashboard: DashboardsService.BOOK_OF_RECORDS_DASHBOARD,
                allowedRoles: DashboardsService.BOOK_OF_RECORDS_DASHBOARD.allowedRoles
              },
              resolve: {
                o7Devices: O7DevicesResolve
              }
            },
            {
              path: 'app-user-state-templates',
              loadChildren: () => import('./modules/app-user-state-templates/app-user-state-templates.module')
                .then(m => m.AppUserStateTemplatesModule),
              data: {
                dashboard: DashboardsService.APP_USER_STATE_TEMPLATES_DASHBOARD,
                allowedRoles: DashboardsService.APP_USER_STATE_TEMPLATES_DASHBOARD.allowedRoles
              },
              resolve: {
                o7Devices: O7DevicesResolve,
                apps: AppsResolve
              }
            },
            {
              path: 'localizator',
              loadChildren: () => import('./modules/localizator/localizator.module').then(m => m.LocalizatorModule),
              data: {
                dashboard: DashboardsService.LOCALIZATOR_DASHBOARD,
                allowedRoles: DashboardsService.LOCALIZATOR_DASHBOARD.allowedRoles
              }
            },
            {
              path: 'starlite-symbolicator',
              loadChildren: () => import('./modules/starlite-symbolicator/starlite-symbolicator.module')
                .then(m => m.StarliteSymbolicatorModule),
              data: {
                dashboard: DashboardsService.STARLITE_SYMBOLICATOR_DASHBOARD,
                allowedRoles: DashboardsService.STARLITE_SYMBOLICATOR_DASHBOARD.allowedRoles
              }
            },
            {
              path: 'request-builder',
              loadChildren: () => import('./modules/request-builder/request-builder.module').then(m => m.RequestBuilderModule),
              data: {
                dashboard: DashboardsService.REQUEST_BUILDER_DASHBOARD,
                allowedRoles: DashboardsService.REQUEST_BUILDER_DASHBOARD.allowedRoles
              },
              resolve: {
                apps: AppsResolve,
                o7Devices: O7DevicesResolve,
                countries: CountryResolve
              }
            },
            {
              path: 'tools',
              loadChildren: () => import('./modules/tools/tools.module').then(m => m.ToolsModule),
              data: {
                dashboard: DashboardsService.TOOLS_DASHBOARD,
                allowedRoles: DashboardsService.TOOLS_DASHBOARD.allowedRoles
              }
            },
            {
              path: 'crosspromo',
              loadChildren: () => import('./modules/crosspromo/crosspromo.module').then(m => m.CrosspromoModule),
              data: {
                dashboard: DashboardsService.CROSSPROMO_KPIS_DASHBOARD,
                allowedRoles: DashboardsService.CROSSPROMO_KPIS_DASHBOARD.allowedRoles
              }
            },
            {
              path: 'events-horizon',
              loadChildren: () => import('./modules/events-horizon/events-horizon.module').then(m => m.EventsHorizonModule),
              data: {
                dashboard: DashboardsService.EVENTS_HORIZON_DASHBOARD,
                allowedRoles: DashboardsService.EVENTS_HORIZON_DASHBOARD.allowedRoles
              }
            },
            {
              path: 'mediation',
              loadChildren: () => import('./modules/mediation/mediation.module').then(m => m.MediationModule),
              data: {
                dashboard: DashboardsService.MEDIATION_DASHBOARD,
                allowedRoles: DashboardsService.MEDIATION_DASHBOARD.allowedRoles
              },
              resolve: {
                countries: CountryResolve
              }
            },
            {
              path: 'mediation-external',
              loadChildren: () => import('./modules/mediation-ext/mediation-ext.module').then(m => m.MediationExtModule),
              data: {
                dashboard: DashboardsService.MEDIATION_EXT_DASHBOARD,
                allowedRoles: DashboardsService.MEDIATION_EXT_DASHBOARD.allowedRoles
              },
              resolve: {
                countries: CountryResolve
              }
            }
          ]
        }
      ]
    },
    {
      path: 'unauthorized/:dashboardName',
      loadChildren:
        () => import('./modules/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule),
    }
  ]
;

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

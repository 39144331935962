import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialAndFlexModule} from './shared/modules/material-and-flex.module';
import {CoreModule, KeycloakAngularModule} from 'keycloak-angular';
import {O7AuthGuard} from './core/guards/o7-auth.guard';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpErrorInterceptor} from './core/interceptors/http-error-interceptor.service';
import {HttpRequestInterceptor} from './core/interceptors/http-request-interceptor.service';
import {WINDOW_PROVIDERS} from './core/providers/window-provider';
import {SharedModule} from './shared/shared.module';
import {CommonModule, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {FilterResolve} from './shared/filters/resolvers/filter-resolve';
import {initializeKeycloak, O7KeycloakInitializer} from './core/authentication/o7-keycloak-initializer';
import {CookieService} from 'ngx-cookie-service';
import {BaseAppComponent} from './pages/base-app/base-app.component';
import {AppsResolve} from './core/common/app/resolvers/apps-resolve.service';
import {O7DevicesResolve} from './shared/o7devices/resolvers/o7-devices-resolve';
import {PlatformResolve} from './core/common/app/resolvers/platform-resolve';
import {CountryResolve} from './core/common/country/resolvers/country-resolve';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {RouterModule} from '@angular/router';
import {Store, StoreModule} from '@ngrx/store';
import {NavigationActionTiming, routerReducer, RouterState, StoreRouterConnectingModule} from '@ngrx/router-store';
import * as Sentry from '@sentry/angular';
import {devVersionReducer} from './ngrx/dev-versions/dev-version.reducer';
import {EffectsModule} from '@ngrx/effects';
import {DevVersionGuard} from './core/guards/dev-version.guard';
import {appClassesReducer} from './core/common/app/ngrx/app-class.reducer';
import {DevVersionService} from './core/dev-versions/dev-version.service';
import {setBeDevVersion, setBeDevRegion, setFeDevVersion, setUseDevEnvironment} from './ngrx/dev-versions/dev-version.actions';
import {EnvGuard} from './core/guards/env.guard';
import {AngularFireModule} from '@angular/fire';
import {environment} from '@environment';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {dashboardUserReducer} from './ngrx/dashboard-user/dashboard-user.reducer';
import {DashboardUserEffects} from './ngrx/dashboard-user/dashboard-user.effects';
import {DevVersionEffects} from './ngrx/dev-versions/dev-version.effects';
import {AppClassEffects} from './core/common/app/ngrx/app-class.effects';


@NgModule({
  imports: [
    // Angular
    BrowserModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    RouterModule,
    CommonModule,

    // Core & Shared
    CoreModule,
    SharedModule,

    // 3rd party
    KeycloakAngularModule,
    BrowserAnimationsModule,
    MaterialAndFlexModule,

    // Dashboards - Only the ones not lazy loaded

    // App
    AppRoutingModule,

    // Store
    StoreModule.forRoot({}),
    StoreModule.forFeature('router', routerReducer),
    StoreModule.forFeature('applicationState', devVersionReducer),
    StoreModule.forFeature('appClasses', appClassesReducer),
    StoreModule.forFeature('dashboardUser', dashboardUserReducer),

    // Effects
    EffectsModule.forRoot([
      DashboardUserEffects,
      DevVersionEffects,
      AppClassEffects
    ]),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
      navigationActionTiming: NavigationActionTiming.PostActivation
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
  ],
  declarations: [AppComponent, BaseAppComponent],
  providers: [
    WINDOW_PROVIDERS,
    O7AuthGuard,
    DevVersionGuard,
    EnvGuard,
    CookieService,
    FilterResolve,
    AppsResolve,
    PlatformResolve,
    O7DevicesResolve,
    CountryResolve,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [O7KeycloakInitializer]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store, devVersionService: DevVersionService) => {
        return () => {
          store.dispatch(setBeDevVersion({beDevVersion: devVersionService.resolvePreSetBeDevVersion()}));
          store.dispatch(setFeDevVersion({feDevVersion: devVersionService.resolvePreSetFeDevVersion()}));
          store.dispatch(setUseDevEnvironment({useDevEnvironment: devVersionService.shouldUseDevEnvironment()}));
          store.dispatch(setBeDevRegion({beDevRegion: devVersionService.resolvePreSetBeDevRegion()}));
        };
      },
      multi: true,
      deps: [Store, DevVersionService]
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

(window as any).global = window;

// @ts-ignore
window.Buffer = window.Buffer || require('buffer').Buffer;

import * as _ from 'lodash';
import {Params} from '@angular/router';

export default class QueryParamUtils {

  static mergeRouterQueryParams(queryParams: Params, params: Params): Params {
    return _.extend(params, queryParams);
  }

  static getUrlWithoutParamsPart (url: string): string {
    return _.first(url.split('?'));
  }
}

import {ActivatedRouteSnapshot} from '@angular/router';
import * as _ from 'lodash';
import {Dashboard} from '../services/dashboards.service';

export class RouterUtils {

  static extractDashboardFromRoute(route: ActivatedRouteSnapshot): Dashboard {
    while (route.firstChild) {
      const dashboard: Dashboard = _.get(route, 'firstChild.data.dashboard');
      if (!_.isEmpty(dashboard)) {
        return dashboard;
      }
      route = route.firstChild;
    }
    console.error('Could not extract dashboard from state:', route.firstChild);
    return null;
  }
}

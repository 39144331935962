import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {DevVersionService} from '../../core/dev-versions/dev-version.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserOrganisationService} from '../../core/organisation/user-organisation.service';
import {setUserEmail, setUserOrganisation, setUserRoles} from './dashboard-user.actions';
import {KeycloakService} from 'keycloak-angular';
import {KEYCLOAK_INIT} from '../common/module-initialization.actions';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DashboardUserEffects {

  constructor(private actions$: Actions,
              private store: Store,
              private router: Router,
              private devVersionService: DevVersionService,
              private userOrganisationService: UserOrganisationService,
              private keycloakService: KeycloakService) {
  }

  setUserOrganisation: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(KEYCLOAK_INIT),
    map(() => setUserOrganisation({userOrganisation: this.userOrganisationService.getUserOrganisation()}))
  ));

  setUserRoles$: any = createEffect(() => this.actions$.pipe(
    ofType(KEYCLOAK_INIT),
    map(() => {
      return setUserRoles({userRoles: this.keycloakService.getUserRoles()});
    })
  ));

  setUserEmail$: any = createEffect(() => this.actions$.pipe(
    ofType(KEYCLOAK_INIT),
    map(() => {
      return setUserEmail({userEmail: _.get(this.keycloakService.getKeycloakInstance().tokenParsed, 'email')});
    })
  ));
}

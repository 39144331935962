import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {Country} from '../classes/country';
import {Builder} from 'builder-pattern';
import {Observable, of} from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  private static BASE_API: string = '/rest/admin/country-codes/official/with-country-name';

  public static readonly GLOBAL: Country = Builder(Country)
    .name('Global')
    .code(null)
    .build();

  private cachedCountries: Country[];

  constructor(private http: HttpClient) {
  }

  getCountries(): Observable<Country[]> {
    if (this.cachedCountries != null) {
      return of(this.cachedCountries);
    }
    return this.http.get<any>(CountriesService.BASE_API).pipe(
      map((response) => {
        return _.map(response, (value: string, key: string) =>
          Builder(Country)
            .name(value)
            .code(key)
            .build());
      }),
      tap((values) => this.cachedCountries = values)
    );
  }

  getCachedCountries(): Country[] {
    return this.cachedCountries;
  }
}

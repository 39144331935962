import {createReducer, on} from '@ngrx/store';
import {ActionReducer} from '@ngrx/store/src/models';
import {setActiveAppClasses} from './app-class.actions';
import {AppClassState, initialState} from './app-class.state';

export const appClassesReducer: ActionReducer<AppClassState> = createReducer(
  initialState,
  on(setActiveAppClasses, (state, {activeAppClasses}) => {
    return {...state, activeAppClasses: activeAppClasses};
  })
);

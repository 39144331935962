import {Type} from 'class-transformer';

export class O7Device {
  readonly uid: string;
  readonly udid: string;
  readonly udidHashHashCode: number;
  readonly owner: string;
  readonly name: string;
  readonly model: string;
  readonly releaseYear: number;
  readonly os: string;
  readonly email: string;
  // Calculated in o7devices.service
  readonly displayName: string;
  readonly platform: string;
  readonly resolution: string;
  readonly aspectRatio: number;
  readonly aspectRatioString: string;
  readonly screenType: string;
  readonly location: string;
  readonly assetId: string;
  readonly specUrl: string;
  readonly notes: string;
  @Type(() => Date)
  readonly dateCreated: Date;
  readonly type: string;
  readonly imei: string;
  readonly imei2: string;
  readonly serialNumber: string;
  readonly locationStatus: string;
  readonly autoGenerated: boolean;
  readonly createdBy: string;
  readonly skipReset: boolean;


  @Type(() => ChangeLogEntry)
  readonly changeLog: ChangeLogEntry[] = [];
  @Type(() => RequestLogEntry)
  readonly requestLog: RequestLogEntry[] = [];

  readonly testBehaviours: string[];
  readonly countryCodeOverride: string;
  readonly regionOverride: string;
  @Type(() => Date)
  readonly curendDateTimeOverride: Date;
  readonly splitIndexOverride: number;
  readonly gridOverride: string;
  readonly navidadOverride: string;
  readonly complianceOverride: string;
  readonly backendVersionOverride: string;
  readonly externalBackendVersion: string;
  readonly adProvidersOverrides: Map<string, string[]>;
  readonly headerBiddingAdAdapterOverrides: string[];
  readonly headerBiddingServerOverride: string;
  readonly headerBiddingBidPriceAdjustment: string;
  readonly headerBiddingRendererOverride: string;
  readonly useDevUniversalCreative: boolean;
  readonly creativeBidder: string;
  readonly creativeCampaign: string;
  readonly creativeId: string;
  readonly adsTestingDevice: boolean;

  get platformTitle(): string {
    return this.platform;
  }

  public hasTestBehaviour(testBehaviour: string): boolean {
    return this.testBehaviours?.includes(testBehaviour);
  }
}

export class ChangeLogEntry {
  readonly userName: string;
  readonly message: string;
  @Type(() => Date)
  readonly date: Date;
}

export class RequestLogEntry {
  readonly requestUrl: string;
  @Type(() => Date)
  readonly date: Date;
  readonly requestBody: string;
}


import {ActionReducer, createReducer, on} from '@ngrx/store';
import {CommonState, initialState, Organisations} from '../state/common.state';
import {setOrganisations} from '../actions/common.actions';
import {Organisation} from '../../entities/organisation';
import * as _ from 'lodash';
import {Builder} from 'builder-pattern';

export const commonReducer: ActionReducer<CommonState> = createReducer(
  initialState,
  on(setOrganisations, (state, {organisations}) => {
    const entities: Organisations = {};
    _.forEach(organisations, (organisation) =>
      (entities[organisation] = Builder<Organisation>()
        .id(organisation.toLowerCase())
        .displayName(organisation)
        .build())
    );
    return {...state, organisations: entities};
  })
);

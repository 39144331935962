import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {O7Device} from '../classes/o7-device';
import * as _ from 'lodash';
import {Builder} from 'builder-pattern';
import {O7DeviceInfo} from '../classes/o7-device-info';

@Injectable({
  providedIn: 'root'
})
export class O7DevicesService {

  private static readonly BASE_URL: string = '/rest/visual7/o7devices/v1';

  private cachedO7Devices: O7Device[] = null;

  constructor(private http: HttpClient) {}

  getO7Devices(): Observable<O7Device[]> {
    if (this.cachedO7Devices != null) {
      return of(this.cachedO7Devices);
    }
    return this.http.get<O7Device[]>(O7DevicesService.BASE_URL + '/')
      .pipe(
        map(o7devices => {
          return plainToClass(O7Device, o7devices);
        }),
        map(o7devices => {
          return _.map(o7devices, (o7device) => {
            return Builder(O7Device, o7device)
              .displayName(this.getDisplayName(o7device))
              .build();
          });
        }),
        tap((o7devices: O7Device[]) => {
          this.cachedO7Devices = o7devices;
        })
      );
  }

  private getDisplayName(o7Device: O7Device): string {
    return [o7Device.owner, o7Device.name].filter(s => s != null).join(' - ') + ` (${o7Device.uid})`;
  }

  getCachedO7Devices(): O7Device[] {
    if (this.cachedO7Devices == null) {
      throw new Error('O7 devices not fetched yet. Use o7 devices resolver!');
    }
    return this.cachedO7Devices;
  }

  findO7Device(uid: string): O7Device | undefined {
    return _.find(this.getCachedO7Devices(), {uid: uid});
  }

  setTestBehaviour(uid: string, testBehaviour: string, remove: boolean): Observable<O7Device> {
    return this.http.put<O7Device>(O7DevicesService.BASE_URL + '/device/' + uid + '/test-behaviour?remove=' + remove, testBehaviour).pipe(
      map(result => {
        return plainToClass(O7Device, result);
      })
    );
  }

  getO7DeviceByUid(uid: string): Observable<O7Device> {
    return this.http.get<O7Device>(O7DevicesService.BASE_URL + '/devices/' + uid)
      .pipe(
        map(result => {
          return plainToClass(O7Device, result);
        })
      );
  }

  createOrUpdateO7Device(uid: string, udid: string, device: O7Device): Observable<O7Device> {
    const params: HttpParams = new HttpParams();
    params.set('uid', device.uid);
    params.set('udid', device.udid);
    const options: { params: HttpParams } = {
      params: params
    };

    return this.http.put<O7Device>(O7DevicesService.BASE_URL + '/devices', device, options);
  }

  getO7DeviceInfo(uid: string): Observable<O7DeviceInfo> {
    return this.http.get<O7DeviceInfo>(O7DevicesService.BASE_URL + '/' + uid)
      .pipe(
        map(o7DeviceInfo => {
          return plainToClass(O7DeviceInfo, o7DeviceInfo);
        }),
        catchError(err => {
          console.error('Error', err);
          return throwError(err);
        })
      );
  }
}

import {Injectable} from '@angular/core';
import {AuthenticationPropertyKey} from '../authentication/authentication-property-key';
import {AuthenticationService} from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserOrganisationService {

  public static OUTFIT7_ORGANISATION_ID: string = 'outfit7';
  public static JINKE_ORGANISATION_ID: string = 'jinke';

  constructor(private authenticationService: AuthenticationService
  ) {}

  getUserOrganisation(): string {
    const availableOrganisations: string[] = this.authenticationService.getProperties(AuthenticationPropertyKey.ORGANISATION);
    if (availableOrganisations.includes(UserOrganisationService.OUTFIT7_ORGANISATION_ID)) {
      return UserOrganisationService.OUTFIT7_ORGANISATION_ID;
    }
    return UserOrganisationService.JINKE_ORGANISATION_ID;
  }

}

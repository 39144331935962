<mat-form-field class="select-search" [appearance]="appearanceMode" [floatLabel]="floatLabel">
  <!-- Label -->
  <mat-label>{{placeHolder}}</mat-label>
  <!-- Select -->
  <mat-select [compareWith]="customCompareWith"
              (selectionChange)="onSelectedObjectChange()"
              [formControl]="selectForm"
              [multiple]="multiple"
              [panelClass]="{'select-panel-fit-content': adjustPanelWidthToText}">
    <!-- Search field -->
    <mat-option>
      <ngx-mat-select-search [noEntriesFoundLabel]="'No matches found'"
                             [formControl]="objectFilterCtrl"
                             [placeholderLabel]="'Search'"
                             data-test="app-select-search-input">
      </ngx-mat-select-search>
    </mat-option>
    <!-- Empty field -->
    <mat-option *ngIf="allowEmpty" [value]="" data-test="app-select-search-option-any">
      Any
    </mat-option>
    <!-- Available options -->
    <!-- Top options -->
    <mat-option class="top-object-option"
                *ngFor="let object of (filteredTopObjects | async)"
                [value]="object"
                [disabled]="isObjectDisabled(object)">
      <span *ngIf="object">
        {{ getDisplayField(object) }}
      </span>
    </mat-option>
    <mat-divider class="top-objects-divider" *ngIf="topObjects"></mat-divider>
    <!-- Other options -->
    <mat-option *ngFor="let object of getOtherOptions(filteredObjects | async, filteredTopObjects | async)"
                [value]="object"
                data-test="app-select-search-option"
                [disabled]="isObjectDisabled(object)">
      <span *ngIf="object">
        {{ getDisplayField(object) }}
      </span>
    </mat-option>
  </mat-select>
  <!-- Clear button -->
  <button *ngIf="enableClear && !disabled" type="button" mat-button matSuffix mat-icon-button aria-label="Clear" class="clear-button"
          (click)="clearSearch($event)">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

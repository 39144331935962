import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {CountriesService} from '../services/countries.service';
import {Country} from '../classes/country';

@Injectable()
export class CountryResolve implements Resolve<Country[]> {

  constructor(private countryService: CountriesService) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    return this.countryService.getCountries();
  }
}

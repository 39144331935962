/**
 * Very important: must be in sync with BE
 */
export class FilterComponent {
  static readonly BASIC: FilterComponent = new FilterComponent('basic');
  static readonly RANGE: FilterComponent = new FilterComponent('range');
  static readonly WHITE_OR_BLACK_LIST: FilterComponent = new FilterComponent('whiteOrBlackList');

  public id: string;

  private constructor(id: string) {
    this.id = id;
  }
}

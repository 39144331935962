import {Builder} from 'builder-pattern';
import {AppClass} from './app-class';
import {Expose, plainToClass, Transform} from 'class-transformer';
import {Platform} from './platform';
import {AndroidAppStore} from "./android-app-store";
import {IosAppStore} from "./ios-app-store";
import * as _ from 'lodash';

export class App {
  @Expose()
  readonly appClassId: string;
  @Expose()
  readonly appId: string;
  @Expose()
  readonly universalApp: boolean;
  @Expose()
  readonly platformTitle: string;
  @Expose()
  readonly discontinued: boolean;
  @Expose()
  readonly storeUrl: string;
  @Expose()
  readonly releasedOnAppStores: string[];

  // possibly value appstorecn, exposed as appstore to avoid confusion
  readonly androidAppStoreId: string;

  @Expose()
  @Transform((value, appJson: App) => {
    if (appJson.platformTitle == Platform.ANDROID) {
      return _.defaultTo(appJson.androidAppStoreId, AndroidAppStore.GPLAY);
    } else if (appJson.platformTitle == Platform.IPHONE || appJson.platformTitle == Platform.IPAD) {
      return _.defaultTo(appJson.androidAppStoreId, IosAppStore.APPSTORE);
    } else if (appJson.platformTitle == Platform.HARMONY_OS) {
      return _.defaultTo(appJson.androidAppStoreId, null);
    }
  })
  readonly appStore: string;

  @Expose()
  @Transform((platformEnumName, appJson: App) => {
    return Builder(Platform)
      .title(appJson.platformTitle)
      .build();
  })
  readonly platform: Platform;

  @Expose()
  @Transform((appClassEnumName, appJson: any) => {
    return plainToClass(AppClass, appJson.appClassProxy);
  })
  readonly appClass: AppClass;

}

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {SlackMember} from '../../models/slack-member.model';


export interface SlackMemberState extends EntityState<SlackMember> {
  loading: boolean;
  loaded: boolean;
}

function selectId(member: SlackMember): string {
  return member.id;
}

function sortComparer(memberA: SlackMember, memberB: SlackMember): number {
  return memberA.profile.display_name.localeCompare(memberB.profile.display_name);
}

export const slackMemberAdapter: EntityAdapter<SlackMember> = createEntityAdapter<SlackMember>({
  selectId: selectId,
  sortComparer: sortComparer
});

export const initialState: SlackMemberState = slackMemberAdapter.getInitialState({
  loading: false,
  loaded: false
});

export const slackMemberFeatureKey: string = 'slackMember';

export interface SlackMemberRootState {
  slackMember: SlackMemberState;
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {from, Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {ChatPostMessageMetadata} from '../models/chat-post-message-metadata.model';
import {ChatPostMessageResponseMetadata} from '../models/chat-post-message-response-metadata.model';
import {ConversationsOpenMetadata} from '../models/conversations-open-metadata.model';
import {ConversationsOpenResponseMetadata} from '../models/conversations-open-response-metadata.model';
import {SlackMember} from '../models/slack-member.model';
import {defaultEnvironment} from '../../../../environments/environment.defaults';

@Injectable()
export class SlackHttpService {

  private static readonly BASE_URL: string = 'https://slack.com/api/';
  private static readonly MEMBERS_URL: string = '/rest/admin/slack/members/';
  private static readonly CONVERSATIONS_OPEN_URL: string = `${SlackHttpService.BASE_URL}conversations.open`;
  private static readonly CHAT_POST_MESSAGE__URL: string = `${SlackHttpService.BASE_URL}chat.postMessage`;

  private static readonly AUTH_TOKEN: string = defaultEnvironment.slackAuthToken;

  constructor(private http: HttpClient,
              private store: Store,
              private snackBar: MatSnackBar) {
  }

  getSlackMembers(): Observable<SlackMember[] | undefined> {
    return this.http.get<SlackMember[] | undefined>(SlackHttpService.MEMBERS_URL)
      .pipe(
        catchError(this.handleError<undefined>('getSlackMembers', undefined)),
      );
  }

  postConversationsOpen(metadata: ConversationsOpenMetadata): Observable<ConversationsOpenResponseMetadata | undefined> {
    let params: HttpParams = new HttpParams()
      .set('token', SlackHttpService.AUTH_TOKEN)
      .set('users', metadata.users);

    return this.makeXMLHttpRequest('POST', SlackHttpService.CONVERSATIONS_OPEN_URL, params)
      .pipe(
        catchError(this.handleError<undefined>('postConversationsOpen', undefined)),
      ) as Observable<ConversationsOpenResponseMetadata | undefined>;

    // return this.http.post<ConversationsOpenResponseMetadata | undefined>(SlackHttpService.CONVERSATIONS_OPEN_URL, { params })
    //   .pipe(
    //     catchError(this.handleError<undefined>('postConversationsOpen', undefined)),
    //   );
  }

  postChatMessage(metadata: ChatPostMessageMetadata): Observable<ChatPostMessageResponseMetadata | undefined> {
    let params: HttpParams = new HttpParams()
      .set('token', SlackHttpService.AUTH_TOKEN)
      .set('channel', metadata.channel)
      .set('blocks', metadata.blocks);

    return this.makeXMLHttpRequest('POST', SlackHttpService.CHAT_POST_MESSAGE__URL, params)
      .pipe(
        catchError(this.handleError<undefined>('postChatPostMessage', undefined)),
      ) as Observable<ChatPostMessageResponseMetadata | undefined>;

    // return this.http.post<ChatPostMessageResponseMetadata | undefined>(SlackHttpService.CHAT_POST_MESSAGE__URL, { params })
    //   .pipe(
    //     catchError(this.handleError<undefined>('postChatPostMessage', undefined)),
    //   );
  }

  private makeXMLHttpRequest(method: 'GET' | 'POST', url: string, params: HttpParams): Observable<unknown> {
    return from(new Promise((resolve, reject) => {
      const request: XMLHttpRequest = new XMLHttpRequest();
      request.onreadystatechange = function (): void {
        if (request.readyState === 4) {
          if (request.status === 200) {
            resolve(JSON.parse(request.response));
          } else {
            reject(request.response);
          }
        }
      };
      request.open(method, url, true);
      request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      request.send(params.toString());
    }));
  }

  private handleError<T>(operation: string, result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      this.snackBar.open(
        `${operation} failed: ${error.message}.`,
        null,
        {
          verticalPosition: 'top',
          duration: 5000
        }
      );
      console.error(error);
      return of(result as T);
    };
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {O7DevicesService} from '../services/o7-devices.service';
import {Observable} from 'rxjs';
import {O7Device} from '../classes/o7-device';

@Injectable()
export class O7DevicesResolve implements Resolve<any> {

  constructor(private o7DevicesService: O7DevicesService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<O7Device[]> {
    return this.o7DevicesService.getO7Devices();
  }
}

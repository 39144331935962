/**
 * Default environment values
 */
export const defaultEnvironment: Environment = {
  angularProductionMode: false,
  firebase: {
    apiKey: 'AIzaSyAtxcRHAow16oSjiG-xbULYQkxnfv_7IiQ',
    authDomain: 'o7apps-firebase.firebaseapp.com',
    databaseURL: 'https://o7apps-firebase.firebaseio.com',
    projectId: 'o7apps-firebase',
    storageBucket: 'o7apps-firebase.appspot.com',
    messagingSenderId: '91500030329',
    appId: '1:91500030329:web:acca3d9c9b1ce65c8027a8',
    measurementId: 'G-VZ7E9PX9L2'
  },
  slackAuthToken: 'xoxb-2303844484-3650928192017-P1dZf6o2i2JMqTfbtvChKH9C'
};

export interface Environment {
  angularProductionMode: boolean;
  firebase?: object;
  slackAuthToken?: string;
}


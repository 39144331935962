import {ActionReducer, createReducer, on} from '@ngrx/store';
import {setBeDevVersion, setDevVersionData, setFeDevVersion, setUseDevEnvironment, setBeDevRegion} from './dev-version.actions';
import {DevVersionData} from '../../core/dev-versions/dev-version-data';

export interface AppContainer {
  devVersionData: DevVersionData;
  beDevVersion: string;
  beDevRegion: string;
  feDevVersion: string;
  useDevEnvironment: boolean;
}

export const initialState: AppContainer = {
  devVersionData: null,
  beDevVersion: 'prod',
  beDevRegion: 'global',
  feDevVersion: null,
  useDevEnvironment: false
};

export const devVersionReducer: ActionReducer<AppContainer> = createReducer(
  initialState,
  on(setDevVersionData, (state, {devVersionData}) => {
    return {...state, devVersionData: devVersionData};
  }),
  on(setBeDevVersion, (state, {beDevVersion}) => {
    return {...state, beDevVersion: beDevVersion};
  }),
  on(setBeDevRegion, (state, {beDevRegion}) => {
    return {...state, beDevRegion: beDevRegion};
  }),
  on(setFeDevVersion, (state, {feDevVersion}) => {
    return {...state, feDevVersion: feDevVersion};
  }),
  on(setUseDevEnvironment, (state, {useDevEnvironment}) => {
    return {...state, useDevEnvironment: useDevEnvironment};
  })
);

import {ActionCreator, NotAllowedCheck, TypedAction} from '@ngrx/store/src/models';
import {createAction, props} from '@ngrx/store';
import { SlackMember } from '../../models/slack-member.model';


export const loadAll: ActionCreator<string, () => TypedAction<string>> = createAction(
  '[SlackMember] Load All'
);

export const loadAllSuccess: ActionCreator<string, (props: {members: SlackMember[]} &
  NotAllowedCheck<{members: SlackMember[]}>) => {members: SlackMember[]} & TypedAction<string>> = createAction(
  '[SlackMember] Load All Success',
  props<{members: SlackMember[]}>()
);

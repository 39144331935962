import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {SlackHttpService} from '../../services/slack-http.service';
import {combineLatest, forkJoin, Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import * as _ from 'lodash';
import {ConversationsOpenResponseMetadata} from '../../models/conversations-open-response-metadata.model';
import * as SlackActions from './slack.actions';

@Injectable()
export class SlackEffects {

  constructor(private actions$: Actions,
              private slackHttpService: SlackHttpService) {
  }

  postChatMessage$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(SlackActions.postChatMessage),
    switchMap(({userIds, blocks}) => {
      const sources: Observable<any>[] = _.map(userIds, (id: string) =>
        this.slackHttpService.postConversationsOpen({users: id})
      );
      return combineLatest([of(blocks), forkJoin(sources)]);
    }),
    switchMap(([blocks, responses]: [string, Array<ConversationsOpenResponseMetadata | undefined>]) => {
      const sources: Observable<any>[] = _.map(responses, (response: ConversationsOpenResponseMetadata) =>
        this.slackHttpService.postChatMessage({
          channel: response.channel.id,
          blocks: blocks
        })
      );
      return forkJoin(sources);
    })
  ), {dispatch: false });

}
